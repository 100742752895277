<template>
  <div>优惠券</div>
</template>

<script>
export default {
  name: 'coupon',
}
</script>

<style lang="less" scoped></style>
